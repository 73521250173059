import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'projects/shared/services/language.service';

@Component({
  selector: 'admin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Macadam.InspectionCompanies.AdministrationPortal';

  constructor(private translate: TranslateService, private languageService: LanguageService) {
    this.initializeLanguage();
  }

  private initializeLanguage(): void {
    this.translate.setDefaultLang('en');

    const language = this.languageService.getPreferredLanguage();
    this.translate.use(language);
  }
}
