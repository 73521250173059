import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthorizeComponent } from '../../../shared/components/authorize/authorize.component';
import { NotFoundLayoutComponent } from '../../../shared/components/layouts/not-found-layout/not-found-layout.component';
import { UnauthorizedComponent } from '../../../shared/components/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: '', redirectTo: 'inspection-companies', pathMatch: 'full' },

  {
    path: 'inspection-companies',
    loadChildren: () => import('./inspection-companies/inspection-companies.module').then((m) => m.InspectionCompanyModule)
  },
  {
    path: 'communications',
    loadChildren: () => import('./communications/communications.module').then((m) => m.CommunicationsModule)
  },
  { path: 'settings', loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule) },
  { path: 'roles', loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule) },
  // Authorization component handler
  { path: 'authorize', component: AuthorizeComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },

  // Handle all other routes
  { path: 'not-found', component: NotFoundLayoutComponent },
  { path: '**', component: NotFoundLayoutComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
